<template>
  <section class="index-news">
    <div class="index-news__container container">
      <h2 class="index-news__title" v-t="'index.news.title'" />
      <div class="index-news__cards">
        <div v-swiper:mySwiper="swiperOption">
          <div class="swiper-wrapper">
            <div class="swiper-slide item" v-for="(item, i) in locale === 'EN' ? newsEn : locale === 'RU' ? newsRu : []" :key="i">
              <div class="item__top">
                <img class="avatar" :src="item.avatar" :alt="item.publisher">
                <div class="date">{{ moment(item.date).format('D MMMM').toLowerCase() }} {{ moment().format('YYYY') !== moment(item.date).format('YYYY') ? moment(item.date).format('YYYY') : '' }}</div>
              </div>
              <p class="item__text">{{ item.text }}</p>
              <a target="_blank" :href="item.link" class="item__link">
                <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.508 5.167h-3.19a.577.577 0 01-.57-.584c0-.322.255-.583.57-.583h4.569c.315 0 .57.261.57.583V9.25a.577.577 0 01-.57.583.577.577 0 01-.571-.583V5.992l-5.878 6.004a.563.563 0 01-.808 0 .592.592 0 010-.825l5.878-6.004zm.808 8.75c0-.322.255-.584.57-.584.316 0 .572.261.572.584v8.166c0 1.611-1.279 2.917-2.855 2.917H6.754C5.178 25 3.9 23.694 3.9 22.083V6.917C3.9 5.306 5.178 4 6.755 4h7.995c.315 0 .571.261.571.583a.577.577 0 01-.571.584H6.755c-.946 0-1.713.783-1.713 1.75v15.166c0 .967.767 1.75 1.713 1.75h14.848c.946 0 1.713-.783 1.713-1.75v-8.166z" fill="#B4B4B4" stroke="#B4B4B4" stroke-width=".3"/></svg>
              </a>
            </div>
          </div>
          <div class="index-news__pagination slider-pagination"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import {mapState} from "vuex";

export default {
  name: "News",

  props: ['newsEn', 'newsRu'],

  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        mousewheel: {
          forceToAxis: true
        },
        pagination: {
          el: '.index-news__pagination',
          type: 'bullets',
          clickable: true
        },
        breakpoints: {
          751: {
            slidesPerView: 2
          },
          1051: {
            slidesPerView: 3
          }
        }
      }
    }
  },

  computed: {
    ...mapState(['locale']),
  },

  methods: {
    moment
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/elements/index/news';
</style>